/*
    This file contains the data for the Events section.
    EXAMPLE:
    {
        title: "Tech Talk Spring 2024", // Title of the event.
        img: "/events/techtalk2024.jpg", // Relative path to the event's image.
        date: "April 15th, 2024", // Date of the event.
    },
*/

export const Events = [
  {
    title: "Spring 2024 Social",
    img: "/events/springSocial2024.jpg",
    date: "January 19th 2024",
  },
  {
    title: "Fall 2023 Halloween Social",
    img: "/events/halloween.JPEG",
    date: "October 29th 2023",
  },

  {
    title: "Fall 2023 Tech Tutorial",
    img: "/events/tech.jpg",
    date: "November 23rd 2023",
  },
  {
    title: "Fall 2023 Clubs Day",
    img: "/events/club.jpeg",
    date: "September 17th 2023",
  },
];

