/*
    This file contains the data for the Alumni section on About Us Page.
    EXAMPLE:
    {
        title: "Full Name of the Alumni", // The full name of the alumni.
        linkedin: "https://www.linkedin.com/in/alumni-linkedin-id/", // URL to the LinkedIn profile of the alumni.
    },
*/

export const Alumni2023 = [
  {
    title: "Andy Zhang",
    linkedin: "https://www.linkedin.com/in/andy-zhang-sfu/",
  },
  {
    title: "Brian Tsou",
    linkedin: "https://www.linkedin.com/in/brianthtsou/",
  },
  {
    title: "Mudit Mittal",
    linkedin: "https://www.linkedin.com/in/mmittal/",
  },
  {
    title: "Teeya Li",
    linkedin: "",
  },
  {
    title: "Kumar Shivam",
    linkedin: "https://www.linkedin.com/in/kumarshivam0002/",
  },
];

export const Alumni2024 = [
  {
    title: "Enoch Muwanguzi",

    linkedin: "https://www.linkedin.com/in/enoch-muwanguzi-42491425b/",
  },
  {
    title: "Kian Hoseinkhani",

    linkedin: "https://www.linkedin.com/in/kian-hosseinkhani-716a311b6/",
  },
  {
    title: "Firm Kong Thong",

    linkedin: "https://www.linkedin.com/in/firm-kongthong/",
  },
  {
    title: "Abishek Deshpande",

    linkedin: "https://www.linkedin.com/in/abhishek-deshpande-a01132179/",
  },
  {
    title: "Kasey Le",

    linkedin: "https://www.linkedin.com/in/kasey-le-43aa31255/",
  },
  {
    title: "Karan Pathak",

    linkedin: "https://www.linkedin.com/in/karanpathak/",
  },
  {
    title: "Alison Lu",

    linkedin: "https://www.linkedin.com/in/alisonlu3/",
  },
  {
    title: "Tomi Lui",

    linkedin: "https://www.linkedin.com/in/tomi-lui-3955631b3/",
  },
  {
    title: "Rithik Agarwal",
    linkedin: "https://www.linkedin.com/in/rithik-agarwal-47017618a/",
  },

  {
    title: "Bhavya Singh",
    linkedin: "https://www.linkedin.com/in/bhavya-singh19/",
  },
  {
    title: "Jaden L.",
    linkedin: "https://www.linkedin.com/in/jaden-s-lee/",
  },
  {
    title: "Izaz Zubayer",
    linkedin: "https://www.linkedin.com/in/izazzubayer/",
  },

  {
    title: "Colwyn Ralph",
    linkedin: "https://www.linkedin.com/in/colwyn-ralph/",
  },
  

  {
    title: "Tavleen Sihota",
    linkedin: "https://www.linkedin.com/in/tavleen-sihota/",
  },
  {
    title: "Yang Liu",
    linkedin: "",
  },
  {
    title: "Manpreet Singh Sarna",
    linkedin: "",
  },
];
